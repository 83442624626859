// define hardcoded list of data here and only change here if required

import { Enums } from '../dropdown-enums';

export const hideIPComponentForInitiatedBy = [
  Enums.InitiatedBy['Facebook'],
  Enums.InitiatedBy['Sms Static'],
  Enums.InitiatedBy['Sms Container'],
  Enums.InitiatedBy['GMB']
];

export const hideTypingIndicatorChatTypes = [
  Enums.InitiatedBy['Facebook'],
  Enums.InitiatedBy['Sms Static'],
  Enums.InitiatedBy['Sms Container'],
  Enums.InitiatedBy['GMB']
];

export const showLastMessageFromMessagesForChatTypes = [
  Enums.InitiatedBy['Facebook'],
  Enums.InitiatedBy['Sms Static'],
  Enums.InitiatedBy['Sms Container']
  // Enums.InitiatedBy['GMB']
];

export const AppointmentStatusVisible = [
  Enums.AppointmentStatus.Booked,
  Enums.AppointmentStatus.Canceled,
  Enums.AppointmentStatus.Rescheduled
];

export const SystemMsgTypes = [
  Enums.ChatMessageType.System,
  Enums.ChatMessageType.Bot,
  Enums.ChatMessageType.NonVisitorSystem
];

export const CRMRoles = [
  Enums.Roles.CRMAdmin,
  Enums.Roles.SalesManager,
  Enums.Roles.SalesManagerReadonly
];
