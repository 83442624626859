import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { userSchemas } from 'features/company/services/companySchemas/schemas';
import { usersSection } from 'features/company/services/companySchemas/initialValues';
import './styles.scss';
import DeleteModal from '../deleteModal';
import { TabComponent, TabsContainer } from 'pub-components/TabsComponent';
import alterImage from 'assets/app-images/alter_image.png';
import { Enums, getObjectKeyByValue } from 'services/dropdown-enums';
import { success, toastMsg } from 'services/utils/variables';
import { ToastTemplate } from 'services/utils/generic.methods';
import { toast } from 'react-toastify';
import UilSpinner from '@iconscout/react-unicons/icons/uil-spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUser,
  setUserToState,
  updateUser,
  getUserAsync,
  uploadUserImage
} from 'store/slices/users/userSlice';
import {
  changePasswordAsync,
  setProfileToState
} from 'store/slices/auth/authSlice';
import storage from 'services/utils/storage';
import ImageCropper from '../ImageCropper';
import UserForm from './UserForm';
import ProfileImage from './ProfileImage';
import ChangePasswordForm from './ChangePasswordForm';
import {
  changePasswordInitialValues,
  changePasswordSchema
} from 'services/schemas/authSchemas';
const UserModal = ({
  showModal,
  eventHideModal,
  title,
  editData,
  setProfileImage,
  defaultTab = 'userDetails',
  logoutHandler
}) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [newImg, setNewImg] = useState('');
  const [loading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);
  const [show, setShow] = useState(false);
  const [imageType, setImageType] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const userProfile = JSON.parse(storage.getItem('Profile'));
  const user = useSelector(selectUser).user;
  let ownProfile = editData.id === userProfile?.UserID;

  const userFormHandler = () => {
    const formData = userFormIk.values;
    let profile = JSON.parse(storage.getItem('Profile'));

    //Update profile only if logged in user is edited
    if (ownProfile) {
      setLoading(true);
      let updatedUser = {
        ...user,
        displayName: formData.displayName,
        email: formData.email
      };
      updateUser(updatedUser)
        .then(data => {
          dispatch(setUserToState(data.data));
          profile.Email = formData.email;
          profile.DisplayName = formData.displayName;
          storage.setItem('Profile', profile);
          dispatch(setProfileToState());
          setLoading(false);
          toast.success(success.saveSuccess('User details', 'updated'));
        })
        .catch(ex => {
          setLoading(false);
          console.error(ex);
        });
    }
  };
  /**
   * @name @changePasswordFormHandler
   * @description Used to connect with store and call change password api then toggle error
   * @requires  changeFormData from the form fields
   * */
  const changePasswordFormHandler = formValues => {
    setOldPasswordError(false);
    let changeFormData = formValues;
    setLoading(true);
    dispatch(changePasswordAsync(changeFormData))
      .then(() => {
        toast.success(success.changePassword);
        setLoading(false);
        handleOnModalClose();
      })
      .catch(() => {
        if (!user.isLockedOut) {
          dispatch(
            getUserAsync({
              params: { id: userProfile.UserID }
            })
          );
        }
        if (user.isLockedOut) {
          toast.error(ToastTemplate.error('The account has been locked out.'));
          setLoading(false);
          setTimeout(() => {
            logoutHandler();
          }, 500);
        } else {
          toast.error(toastMsg.errors.changepassword);
          setOldPasswordError(true);
          setLoading(false);
        }
      });
  };
  /** Init Formik */
  const userFormIk = useFormik({
    initialValues: usersSection,
    validationSchema: userSchemas,
    onSubmit: userFormHandler
  });

  /** Init Formik */
  const changePasswordFormIk = useFormik({
    initialValues: changePasswordInitialValues,
    validationSchema: changePasswordSchema,
    onSubmit: changePasswordFormHandler
  });
  // useEffect
  useEffect(() => {
    const valuesChanged = userFormIk.dirty;
    const oldDisplayName = userFormIk.values.displayName;
    const oldEmail = userFormIk.values.email;
    if (editData && Object.keys(editData).length) {
      userFormIk.setValues({
        displayName: valuesChanged ? oldDisplayName : editData.displayName,
        email: valuesChanged ? oldEmail : editData.email,
        userType: getObjectKeyByValue(Enums.UserType, editData?.userType),
        userId: editData.id,
        userName: editData.username,
        confirmPassword: '',
        lockedOut: editData.isLockedOut,
        preferMobile: editData.preferMobileChats
      });
      ownProfile = editData.id === userProfile?.UserID;
      const { imageCloudSecureUrl, imageUrl } = editData;
      setImageUrl(imageCloudSecureUrl || imageUrl);
    }
  }, [editData]);

  useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);
  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    userFormIk.handleChange(e);
    setFieldErrorOnSubmit(false);
  };
  /**
  /**
   * to reset the form fields when close the modal with close icon
   */
  const handleOnModalClose = () => {
    userFormIk.handleReset();
    changePasswordFormIk.handleReset();
    setOldPasswordError(false);
    setFieldErrorOnSubmit(false);
    let updatedProfile = JSON.parse(storage.getItem('Profile'));
    if (updatedProfile.RequirePasswordReset) {
      logoutHandler();
    }
    setSelectedTab('userDetails');
    eventHideModal();
  };

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      setSelectedTab(tabKey);
    }
  };
  const onErrorImageUrl = e => {
    setImageUrl(alterImage);
  };
  const clickImageUpload = () => {
    hiddenFileInput.current.click();
  };
  const handleImageUpload = event => {
    const img = event.target.files[0];
    var pattern = /image-*/;
    var sizeInMB = (img.size / (1024 * 1024)).toFixed(2);
    if (!img.type.match(pattern)) {
      toast.error(
        ToastTemplate.error('Only jpg/jpeg and png files are allowed!')
      );
      return;
    } else if (sizeInMB > 1) {
      toast.error(ToastTemplate.error('File size must not exceed 1 MB'));
      return;
    }
    // setImageUrl(URL.createObjectURL(img));
    setNewImg(URL.createObjectURL(img));
    setImageType(img.type);
    setShowImageCropper(true);
  };
  const removeImage = async () => {
    const response = await fetch(alterImage);
    const blob = await response.blob();
    const file = new File([blob], 'alter_image.png', {
      type: 'image/png',
      lastModified: new Date().getTime()
    });

    var form = new FormData();
    form.append(`file`, file);

    uploadUserImage(form, userFormIk.values.userId).then(res => {
      if (res.data.success) {
        eventSetImage(alterImage);
        setOpenDeleteModal(false);
      } else {
        toast.error(res.data.error);
      }
    });
    eventSetImage(alterImage);
    setOpenDeleteModal(false);
  };
  const eventHideImageCropper = () => {
    setShowImageCropper(false);
  };
  const eventSetImage = newCloudUrl => {
    let profile = JSON.parse(storage.getItem('Profile'));
    profile.ImageUrl = newCloudUrl;
    storage.setItem('Profile', profile);

    dispatch(setProfileToState());

    dispatch(
      getUserAsync({
        params: { id: profile.UserID }
      })
    );

    setImageUrl(newCloudUrl);
    setProfileImage(newCloudUrl);
  };
  const buttonLabel = () => {
    if (selectedTab === 'changePassword') return 'Change Password';
    else return 'Save';
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleOnModalClose}
        size={'lg'}
        keyboard={false}
        id="userModal"
        scrollable
        dialogClassName="userModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="user-modal-header-container">
          <Modal.Header className="user-modal-header">
            <p>{title}</p>
          </Modal.Header>
          <ProfileImage
            clickImageUpload={clickImageUpload}
            userFormik={userFormIk}
            handleImageUpload={handleImageUpload}
            hiddenFileInput={hiddenFileInput}
            show={show}
            setShow={setShow}
            imageUrl={
              userProfile?.RequirePasswordReset
                ? userProfile.ImageUrl
                : imageUrl
            }
            onErrorImageUrl={onErrorImageUrl}
            setOpenDeleteModal={setOpenDeleteModal}
          />
        </div>

        <Modal.Body className="user-modal-body">
          <div className={'navbar-expand'}>
            <TabsContainer
              onTabSelect={e => handleTabSelect(e)}
              activeKey={selectedTab}
              leftHoverBtn={false}
              rightHoverBtn={false}
              closeAllBtn={false}
            >
              {userProfile && !userProfile.RequirePasswordReset && (
                <TabComponent
                  tabClassName={'tab-ui'}
                  eventKey={'userDetails'}
                  title={<div className="tab-styles">User Details</div>}
                >
                  <UserForm
                    userFormIk={userFormIk}
                    fieldErrorOnSubmit={fieldErrorOnSubmit}
                    handleOnChange={handleOnChange}
                    ownProfile={ownProfile}
                  />
                </TabComponent>
              )}
              <TabComponent
                tabClassName={'tab-ui'}
                eventKey={'changePassword'}
                title={<div className="tab-styles">Change Password</div>}
                disabled={!ownProfile}
              >
                <ChangePasswordForm
                  changePasswordFormIk={changePasswordFormIk}
                  oldPasswordError={oldPasswordError}
                  requirePasswordReset={
                    userProfile && userProfile.RequirePasswordReset
                  }
                />
              </TabComponent>
            </TabsContainer>
          </div>
        </Modal.Body>
        <Modal.Footer className="user-modal-footer">
          <Button
            type="button"
            variant="outline-primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={() => handleOnModalClose()}
          >
            <span>Close</span>
          </Button>
          {ownProfile && (
            <Button
              type="submit"
              variant="primary"
              disabled={
                loading ||
                (selectedTab === 'userDetails'
                  ? !userFormIk.isValid
                  : !changePasswordFormIk.isValid)
              }
              className="passBtn btn-md btn-flex float-right"
              onClick={() =>
                selectedTab === 'userDetails'
                  ? userFormIk.submitForm()
                  : changePasswordFormIk.submitForm()
              }
            >
              <span>{buttonLabel()}</span>
              {loading && <UilSpinner className="spinner" />}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ImageCropper
        showModal={showImageCropper}
        eventHideModal={eventHideImageCropper}
        imageUrl={newImg}
        imageType={imageType}
        eventSetNewImage={setNewImg}
        eventSetImage={eventSetImage}
        userId={userFormIk.values.userId}
      />
      <DeleteModal
        onSubmit={removeImage}
        eventHideModal={setOpenDeleteModal}
        showModal={openDeleteModal}
        title="Remove Image"
        infoText="Are you sure you want to remove this photo?"
        submitButtonTitle="Yes"
      />
    </>
  );
};

export default UserModal;
