import CopyButton from 'pub-components/CellRenderer/CopyButton';
import dropdownEnums, {
  Enums,
  enum_InitiatedByAll,
  getObjectKeyByValue
} from 'services/dropdown-enums';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
// import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
// import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import StatusButton from 'pub-components/CellRenderer/StatusButton';
import TimeStamp from 'pub-components/CellRenderer/TimeStamp';
import StatusIndicator from 'pub-components/CellRenderer/StatusIndicator';
import MyReportStatus from 'pub-components/CellRenderer/MyReportStatus';
import Hover from 'pub-components/CellRenderer/Hover';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import GridIdField from 'pub-components/GridIdField';
import CustomDateFilter from './custom-date-filter';
export const checkEnable = (relation = 0, roles = []) => {
  if (relation === Enums.Relationship.Administrator) return true;
  else return relation && roles.includes(relation);
};

const checkEnabledWithFlag = (relation, flag) => {
  let roles = [Enums.Relationship.Customer, Enums.Relationship.Partner];
  let result = checkEnable(relation) || (checkEnable(relation, roles) && flag);
  return result;
};

const numbers = [1, 2, 3, 4, 5];
let getIcon = (id, isEmpty) =>
  isEmpty ? (
    <StarBorderIcon
      key={id}
      style={{
        color: '#ffda00',
        fontSize: '1rem'
      }}
    />
  ) : (
    <StarIcon
      key={id}
      style={{
        color: '#ffda00',
        stroke: '#ffda00',
        fontSize: '1rem'
      }}
    />
  );

// companies Columns
export const CompaniesColumns = (relation, canRequestLead) => [
  {
    name: 'id',
    header: 'ID',
    // className: 'hide-filter-button',
    minWidth: 100,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    minWidth: 180,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    isEnable: true,
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyKey',
    header: 'Company Key',
    minWidth: 150,
    defaultFlex: 2,
    filterDelay: 800,
    isEnable: true,
    enableFilter: true,
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'active',
    header: 'Active',
    minWidth: 120,
    defaultFlex: 1,
    isEnable: true,
    enableFilter: true,
    className: 'hide-filter-button',
    filterType: 'select',
    type: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'hostName',
    header: 'Host',
    minWidth: 150,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'hasInvalidEmails',
    header: 'Invalid Emails',
    minWidth: 130,
    defaultFlex: 1,
    enableFilter: true,
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'invitationVersion',
    header: 'Version',
    minWidth: 130,
    enableFilter: true,
    isEnable: checkEnable(relation),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '0',
          label: 'V1'
        },
        {
          id: '1',
          label: 'V2'
        },
        {
          id: '2',
          label: 'V3'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'hasInboundEmails',
    header: 'Inbound Emails',
    minWidth: 130,
    defaultFlex: 1,
    enableFilter: true,
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'domain',
    header: 'Website',
    minWidth: 150,
    defaultFlex: 1,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'preferInCountryAgents',
    header: 'Geographical Preference',
    minWidth: 200,
    defaultFlex: 1,
    enableFilter: true,
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'startDate',
    header: 'Start Date',
    minWidth: 150,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'date',
    isEnable: true,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'modifiedOn',
    header: 'Modified',
    minWidth: 150,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'date',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    minWidth: 150,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'date',
    isEnable: true,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'pacingEnabled',
    header: 'Pacing',
    minWidth: 100,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'select',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Enabled'
        },
        {
          id: 'false',
          label: 'Disabled'
        }
      ]
    },
    render: ({ value }) => (
      <StatusButton value={value} variant="enable-status" />
    )
  },
  {
    name: 'budgetPauseEnabled',
    header: 'Paused',
    minWidth: 130,
    defaultFlex: 1,
    enableFilter: true,
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'verified',
    header: 'Verified',
    minWidth: 100,
    defaultFlex: 1,
    headerProps: { className: 'hide-filter-button' },
    enableFilter: true,
    filterType: 'select',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'lastLeadSentDate',
    header: 'Last Lead Sent On',
    minWidth: 150,
    defaultFlex: 2,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    enableFilter: true,
    filterType: 'date',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'leadEmailAddresses',
    header: 'Lead Email Addresses',
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Customer]),
    minWidth: 180,
    defaultFlex: 2,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },

    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'requireSecureLeads',
    header: 'Secure Leads',
    minWidth: 130,
    defaultFlex: 1,
    headerProps: { className: 'hide-filter-button' },
    enableFilter: true,
    filterType: 'select',
    isEnable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'copyEntireTranscriptToLead',
    header: 'Transcript in Notes',
    minWidth: 180,
    defaultFlex: 1,
    enableFilter: true,
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'includeTranscriptDetails',
    header: 'Transcript in Leads',
    minWidth: 180,
    defaultFlex: 1,
    enableFilter: true,
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'quickConnectEnabled',
    header: 'Call Connect Enabled',
    minWidth: 180,
    defaultFlex: 1,
    filterEditor: SelectFilter,
    headerProps: { className: 'hide-filter-button' },
    enableFilter: true,
    filterType: 'select',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'priority',
    header: 'Priority',
    minWidth: 180,
    filterEditor: SelectFilter,
    headerProps: { className: 'hide-filter-button' },
    enableFilter: true,
    filterType: 'select',
    isEnable: checkEnable(relation),
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '1',
          label: 'Normal'
        },
        {
          id: '2',
          label: 'Medium'
        },
        {
          id: '3',
          label: 'High'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'primaryContactName',
    header: 'Primary Contact Name',
    minWidth: 180,
    defaultFlex: 1,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'primaryContactEmail',
    header: 'Primary Contact Email',
    minWidth: 180,
    defaultFlex: 1,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'primaryContactPhone',
    header: 'Primary Contact Phone Number',
    minWidth: 240,
    defaultFlex: 1,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'quickConnectPhoneNumber',
    header: 'Call Connect Phone Number',
    minWidth: 220,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'canRequestLeadChange',
    header: 'Request Lead Change',
    minWidth: 180,
    defaultFlex: 1,
    enableFilter: true,
    isEnable:
      checkEnable(relation) ||
      (checkEnable(relation, [Enums.Relationship.Partner]) && canRequestLead),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'referredByCompanyKey',
    header: 'Referred By',
    minWidth: 180,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'salesManagerName',
    header: 'Sales Manager',
    minWidth: 180,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },

    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'smsEnabled',
    header: 'SMS Enabled',
    minWidth: 130,
    enableFilter: true,
    isEnable: checkEnable(relation, [Enums.Relationship.Customer]),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'exitPopupEnabled',
    header: 'Exitpopup Enabled',
    minWidth: 130,
    enableFilter: true,
    isEnable: checkEnable(relation),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'preChatEnabled',
    header: 'Pre-chat Enabled',
    minWidth: 130,
    enableFilter: true,
    isEnable: checkEnable(relation),
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'companyGroups',
    header: 'Groups',
    minWidth: 180,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  }
];

// chat Columns
export const ChatColumns = (relation, isAppointment, operatorsEnabled) => [
  {
    name: 'id',
    header: 'ID',
    // className: 'hide-filter-button',
    minWidth: 100,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    filterDelay: 800,
    minWidth: 180,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyKey',
    header: 'Company Key',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'pickedUpByAgentUsername',
    header: 'Picked by Agent',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    minWidth: 180,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'date',
    isEnable: checkEnable(relation, [
      Enums.Relationship.Customer,
      Enums.Relationship.Partner
    ]),
    cellProps: { className: 'copy-button' },
    dateFormat: 'DD-MMMM-YYYY',
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'averageRating',
    header: 'Avg. Rating',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button custom-rating',
    filterType: 'select',
    type: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '0',
          label: <span>{numbers.map(num => getIcon(num, true))}</span>
        },
        {
          id: '1',
          label: <span>{numbers.map(num => getIcon(num, num > 1))}</span>
        },
        {
          id: '2',
          label: <span>{numbers.map(num => getIcon(num, num > 2))}</span>
        },
        {
          id: '3',
          label: <span>{numbers.map(num => getIcon(num, num > 3))}</span>
        },
        {
          id: '4',
          label: <span>{numbers.map(num => getIcon(num, num > 4))}</span>
        },
        {
          id: '5',
          label: <span>{numbers.map(num => getIcon(num, num > 5))}</span>
        }
      ]
    },
    render: ({ value }) => value || 'No Rating'
  },
  {
    name: 'modifiedOn',
    header: 'Modified',
    minWidth: 180,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'date',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    dateFormat: 'DD-MMMM-YYYY',
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },

  {
    name: 'pickedUpByOperator',
    header: 'Picked by Operator',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    className: 'hide-filter-button',
    filterType: 'select',
    type: 'select',
    isEnable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'assignedToAgentUsername',
    header: 'Assigned to Agent',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'assignedToOperatorUsername',
    header: 'Assigned to Operator',
    filterDelay: 800,
    minWidth: 180,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnabledWithFlag(relation, operatorsEnabled),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'webToSmsStatus',
    header: 'Web to Text',
    className: 'hide-filter-button',
    minWidth: 120,
    defaultFlex: 1,
    isEnable: true,
    visible: true,
    enableFilter: true,
    filterType: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: dropdownEnums.enum_webToSmsStatus.map(val => ({
        id: String(val.value),
        label: val.name
      }))
    },
    render: ({ value }) =>
      dropdownEnums.enum_webToSmsStatus.map(
        item => item.value === value && item.name
      )
  },
  {
    name: 'pickedUpOn',
    header: 'Picked up on',
    minWidth: 180,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'date',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    dateFormat: 'DD-MMMM-YYYY',
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'endedOn',
    header: 'Ended on',
    minWidth: 180,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'date',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    dateFormat: 'DD-MMMM-YYYY',
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'initiatedBy',
    header: 'Initiated By',
    minWidth: 150,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'select',
    isEnable: checkEnable(relation, [
      Enums.Relationship.Customer,
      Enums.Relationship.Partner
    ]),
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        { id: '', label: 'All' },
        { id: '0', label: 'Unknown' },
        { id: '1', label: 'Bar' },
        { id: '2', label: 'Dom' },
        { id: '3', label: 'Window' },
        { id: '4', label: 'Static' },
        { id: '5', label: 'Standard' },
        { id: '6', label: 'Tab' },
        { id: '7', label: 'ExitPopup' },
        { id: '8', label: 'SmsStatic' },
        { id: '9', label: 'SmsContainer' },
        { id: '10', label: 'FacebookMessenger' },
        { id: '11', label: 'Video' },
        { id: '12', label: 'PreChat' },
        { id: '13', label: 'SmsWeb' },
        { id: '14', label: 'WebApi' },
        { id: '15', label: 'GoogleMyBusiness' }
      ]
    },
    cellProps: { className: 'copy-button' },
    render: ({ value }) =>
      enum_InitiatedByAll.map(item => item.value === value && item.name)
  },
  {
    name: 'ipAddress',
    header: 'IP address',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'location',
    header: 'Location',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',

    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'referrer',
    header: 'Referrer',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',

    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'originalReferrer',
    header: 'Original Referrer',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'landingReferrer',
    header: 'Landing Referrer',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'isTest',
    header: 'Test Chat?',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'isLead',
    header: 'Lead?',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: true,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'leadType',
    header: 'Lead Type',
    minWidth: 120,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'select',
    isEnable: true,
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '1',
          label: 'Sales'
        },
        {
          id: '2',
          label: 'Service'
        },
        {
          id: '3',
          label: 'Other'
        }
      ]
    },
    render: ({ value }) =>
      dropdownEnums.enum_LeadType.map(item => item.value === value && item.name)
  },
  {
    name: 'hostName',
    header: 'Host',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'isPhone',
    header: 'Phone?',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: true,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'isTablet',
    header: 'Tablet?',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: true,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'isSMS',
    header: 'SMS?',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: true,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'threeLetterISOLanguageName',
    header: 'Language',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'isBlank',
    header: 'Blank Chat',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'isSpam',
    header: 'Spam',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'transferredToLanguage',
    header: 'Transfered to Language',
    filterDelay: 800,
    minWidth: 200,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'isTransferredChat',
    header: 'Transfered',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },

  {
    name: 'facebookAdId',
    header: 'Facebook Ad Id',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'facebookReferrerSource',
    header: 'Facebook Referrer Source',
    filterDelay: 800,
    minWidth: 200,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'facebookReferrerType',
    header: 'Facebook Referrer Type',
    filterDelay: 800,
    minWidth: 180,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'facebookParameterValue',
    header: 'Facebook Parameter Value',
    filterDelay: 800,
    minWidth: 200,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'transferedTo',
    header: 'Transfered To',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    isEnable: checkEnable(relation),
    enableFilter: true,
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'isVideoChat',
    header: 'Video Chat',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: true,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  // {
  //   name: 'initiatedByName',
  //   header: 'Initiated by name',
  //   filterDelay: 800,
  //   minWidth: 150,
  //   defaultFlex: 3,
  //   enableFilter: true,
  //   filterType: 'string',
  //   isEnable: true,
  //   cellProps: { className: 'copy-button' },
  //   render: ({ value }) => <CopyButton value={value} />
  // },
  {
    name: 'groupName',
    header: 'Group',
    filterDelay: 800,
    minWidth: 150,
    defaultFlex: 3,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'appointmentStatus',
    header: 'Appointment',
    className: 'hide-filter-button',
    minWidth: 120,
    defaultFlex: 2,
    isEnable: checkEnabledWithFlag(relation, isAppointment),
    visible: true,
    enableFilter: true,
    filterType: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: dropdownEnums.enum_appointmentStatus.map(val => ({
        id: String(val.value),
        label: val.name
      }))
    },
    // render: ({ value }) => <MyReportStatus value={value} />
    render: ({ value }) =>
      dropdownEnums.enum_appointmentStatus.map(
        item => item.value === value && item.name
      )
  }
];

// lead Columns
export const LeadsColumns = (relation, isEncrypt, isAppointment) => [
  {
    name: 'id',
    header: 'ID',
    // className: 'hide-filter-button',
    minWidth: 100,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    minWidth: 180,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyKey',
    header: 'Company Key',
    minWidth: 180,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'name',
    header: 'Name',
    minWidth: 150,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'phone',
    header: 'Phone',
    minWidth: 200,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'string',
    isEnable: !isEncrypt,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'email',
    header: 'Email',
    minWidth: 200,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'string',
    isEnable: !isEncrypt,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'webToSmsStatus',
    header: 'Web to Text',
    className: 'hide-filter-button',
    minWidth: 120,
    defaultFlex: 1,
    isEnable: true,
    visible: true,
    enableFilter: true,
    filterType: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: dropdownEnums.enum_webToSmsStatus.map(val => ({
        id: String(val.value),
        label: val.name
      }))
    },
    render: ({ value }) =>
      dropdownEnums.enum_webToSmsStatus.map(
        item => item.value === value && item.name
      )
  },
  {
    name: 'processedByCustomer',
    header: 'Processed',
    minWidth: 150,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'select',
    isEnable: true,
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <span>{value ? 'Yes' : 'No'}</span>
  },
  {
    name: 'domain',
    header: 'Website',
    minWidth: 150,
    defaultFlex: 1,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    minWidth: 180,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'date',
    isEnable: true,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'username',
    header: 'Sent By',
    minWidth: 150,
    filterDelay: 800,
    enableFilter: true,
    isEnable: checkEnable(relation),
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'leadType',
    header: 'Type',
    minWidth: 120,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'select',
    isEnable: true,
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '1',
          label: 'Sales'
        },
        {
          id: '2',
          label: 'Service'
        },
        {
          id: '3',
          label: 'Other'
        }
      ]
    },
    render: ({ value }) =>
      dropdownEnums.enum_LeadType.map(item => item.value === value && item.name)
  },
  {
    name: 'specialInstruction',
    header: 'Special Instructions',
    minWidth: 150,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'initiatedBy',
    header: 'Initiated By',
    minWidth: 150,
    defaultFlex: 1,
    enableFilter: true,
    filterType: 'select',
    isEnable: true,
    headerProps: { className: 'hide-filter-button' },
    filterDelay: 800,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        { id: '', label: 'All' },
        { id: '0', label: 'Unknown' },
        { id: '1', label: 'Bar' },
        { id: '2', label: 'Dom' },
        { id: '3', label: 'Window' },
        { id: '4', label: 'Static' },
        { id: '5', label: 'Standard' },
        { id: '6', label: 'Tab' },
        { id: '7', label: 'ExitPopup' },
        { id: '8', label: 'SmsStatic' },
        { id: '9', label: 'SmsContainer' },
        { id: '10', label: 'FacebookMessenger' },
        { id: '11', label: 'Video' },
        { id: '12', label: 'PreChat' },
        { id: '13', label: 'SmsWeb' },
        { id: '14', label: 'WebApi' },
        { id: '15', label: 'GoogleMyBusiness' }
      ]
    },
    cellProps: { className: 'copy-button' },
    render: ({ value }) =>
      enum_InitiatedByAll.map(item => item.value === value && item.name)
  },
  // {
  //   name: 'initiatedByName',
  //   header: 'Initiated By Name',
  //   minWidth: 150,
  //   filterDelay: 800,
  //   defaultFlex: 1,
  //   enableFilter: true,
  //   filterType: 'string',
  //   isEnable: true,
  //   cellProps: { className: 'copy-button' },
  //   render: ({ value }) => <CopyButton value={value} />
  // },
  {
    name: 'leadCategoryName',
    header: 'Category',
    minWidth: 150,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'hostName',
    header: 'Host',
    minWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation, [Enums.Relationship.Partner]),
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'referred',
    header: 'Referred',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'referralAccepted',
    header: 'Accepted',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'denied',
    header: 'Denied',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'deniedDate',
    header: 'Denied Date',
    minWidth: 150,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'date',
    visible: true,
    isEnable: checkEnable(relation),
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'make',
    header: 'Make',
    minWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'model',
    header: 'Model',
    minWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'dealershipDepartment',
    header: 'Dealership Department',
    minWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'string',
    isEnable: true,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'isSMS',
    header: 'SMS?',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: true,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'groupName',
    header: 'Group',
    minWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'string',
    isEnable: checkEnable(relation),
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'hasKeyfacts',
    header: 'Has KeyFacts?',
    minWidth: 150,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: true,
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  },
  {
    name: 'appointmentStatus',
    header: 'Appointment',
    className: 'hide-filter-button',
    minWidth: 120,
    defaultFlex: 2,
    isEnable: checkEnabledWithFlag(relation, isAppointment),
    visible: true,
    enableFilter: true,
    filterType: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: dropdownEnums.enum_appointmentStatus.map(val => ({
        id: String(val.value),
        label: val.name
      }))
    },
    render: ({ value }) =>
      dropdownEnums.enum_appointmentStatus.map(
        item => item.value === value && item.name
      )
  },
  {
    name: 'hasLeadNotes',
    header: 'Notes?',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    defaultFlex: 2,
    isEnable: checkEnable(relation),
    className: 'hide-filter-button',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => {
      return <span>{value ? 'Yes' : 'No'}</span>;
    }
  }
];

// provision Columns
export const ProvisionColumns = relation => [
  {
    name: 'id',
    header: 'ID',
    // className: 'hide-filter-button',
    minWidth: 100,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    defaultWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'string',
    visible: true,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'hostName',
    header: 'Host',
    defaultWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'string',
    visible: true,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'status',
    header: 'Status',
    className: 'hide-filter-button',
    defaultWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'select',
    visible: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '1',
          label: 'Adding Information'
        },
        {
          id: '2',
          label: 'Waiting For Setup'
        },
        {
          id: '3',
          label: 'Need More Information'
        },
        {
          id: '4',
          label: 'Pending Addition To Website'
        },
        {
          id: '5',
          label: 'Completed'
        }
      ]
    },
    render: ({ value }) => <StatusIndicator value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    defaultWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'date',
    visible: true,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'modifiedOn',
    header: 'Modified',
    defaultWidth: 200,
    defaultFlex: 2,
    enableFilter: true,
    filterType: 'date',
    visible: true,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  }
];

// myReports Columns
export const MyReportsColumns = relation => [
  {
    name: 'name',
    header: 'Reports',
    defaultFlex: 4,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    visible: true,
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    defaultFlex: 2,
    visible: true,
    enableFilter: true,
    filterType: 'date',
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  }
];

// queReports Columns
export const QueReportsColumns = relation => [
  {
    name: 'id',
    header: 'ID',
    // className: 'hide-filter-button',
    minWidth: 100,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'reportStatusId',
    header: 'Status',
    className: 'hide-filter-button',
    minWidth: 100,
    defaultFlex: 2,
    visible: true,
    enableFilter: true,
    filterType: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: '0',
          label: 'Queued'
        },
        {
          id: '1',
          label: 'Running'
        },
        {
          id: '2',
          label: 'Completed'
        },
        {
          id: '3',
          label: 'Completed Without Data'
        },
        {
          id: '4',
          label: 'Failed'
        }
      ]
    },
    render: ({ value }) => <MyReportStatus value={value} />
  },
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 2,
    minWidth: 150,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    visible: true,
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'parametersJson',
    header: 'Parameters',
    defaultFlex: 3,
    minWidth: 180,
    filterDelay: 800,
    visible: true,
    enableFilter: true,
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => {
      return <Hover value={value} />;
    }
  },
  {
    name: 'createdOn',
    header: 'Created',
    defaultFlex: 2,
    minWidth: 120,
    dateFormat: 'DD-MMMM-YYYY',
    enableFilter: true,
    filterType: 'date',
    cellProps: { className: 'copy-button' },
    filterEditor: CustomDateFilter,
    visible: true,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  }
];

// queReports Columns
export const companyUserColumns = relation => [
  {
    name: 'id',
    header: 'ID',
    // className: 'hide-filter-button',
    minWidth: 100,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    isEnable: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'username',
    header: 'User Name',
    defaultFlex: 4,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'string',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'displayName',
    header: 'Display Name',
    defaultFlex: 4,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'string',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  }
];

// user management Columns
export const userManagementColumns = relation => [
  {
    name: 'displayName',
    header: 'Name',
    defaultFlex: 4,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'string',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'email',
    header: 'Email',
    defaultFlex: 4,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'string',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'userType',
    header: 'User Type',
    defaultFlex: 2,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: generateUserTypeOptions(relation)
    },
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => {
      let type = getObjectKeyByValue(Enums.UserType, value);
      return <CopyButton value={type} />;
    }
  }
];

// operators columns
export const operatorsColumns = (relation, callback) => [
  {
    name: 'id',
    header: 'ID',
    minWidth: 100,
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'string',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'username',
    header: 'Username',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'string',
    className: 'hide-filter-button'
  },
  {
    name: 'displayName',
    header: 'Name',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'string',
    className: 'hide-filter-button',
    render: ({ value }) => (
      <a className="btn-link" style={{ cursor: 'pointer' }} onClick={callback}>
        {value}
      </a>
    )
  },
  {
    name: 'active',
    header: 'Active',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    className: 'hide-filter-button',
    filterType: 'select',
    type: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'online',
    header: 'Online',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    className: 'hide-filter-button',
    filterType: 'select',
    type: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Online'
        },
        {
          id: 'false',
          label: 'Offline'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'activeChats',
    header: 'Active Chats',
    minWidth: 110,
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    className: 'hide-filter-button'
  },
  {
    name: 'sessionCount',
    header: 'Session',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    className: 'hide-filter-button'
  },
  {
    name: 'firstActiveToday',
    header: 'First Active',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    dateFormat: 'DD-MMMM-YYYY',
    filterType: 'date',
    className: 'hide-filter-button',
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} time={true} />
  },
  {
    name: 'lastLogoutToday',
    header: 'Last Logout',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    dateFormat: 'DD-MMMM-YYYY',
    filterType: 'date',
    className: 'hide-filter-button',
    filterEditor: CustomDateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} time={true} />
  },
  {
    name: 'activeMinutesToday',
    header: 'Active Time',
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    className: 'hide-filter-button',
    render: ({ value }) => <TimeStamp value={value} isDuration={true} />
  },
  {
    name: 'totalChatsAssignedToday',
    header: 'Chats Assigned',
    minWidth: 130,
    defaultFlex: 1,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    className: 'hide-filter-button'
  },
  {
    name: 'blankChatsToday',
    header: 'Blank Chats',
    defaultFlex: 1,
    minWidth: 110,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    className: 'hide-filter-button'
  },
  {
    name: 'totalLeadsCreatedToday',
    header: 'Leads Created',
    defaultFlex: 1,
    minWidth: 120,
    filterDelay: 800,
    defaultVisible: true,
    enableFilter: false,
    filterType: 'number',
    filterEditor: GridIdField,
    type: 'number',
    className: 'hide-filter-button',
    render: ({ value, data }) => {
      const percent1 = (
        (100 * data.totalLeadsCreatedToday) /
        (data.totalChatsAssignedToday || 1)
      ).toFixed(1);
      const percent2 = (
        (100 * data.totalLeadsCreatedToday) /
        (data.totalChatsAssignedToday - data.blankChatsToday || 1)
      ).toFixed(1);
      return `${value} (${percent1}%, ${percent2}%)`;
    }
  }
];

const generateUserTypeOptions = relation => {
  let options = [{ id: '', label: 'All' }];
  let isAdminCompany = relation === Enums.Relationship.Administrator;
  let customOptions = dropdownEnums.enum_UserType.map(val => ({
    id: val.value,
    label: val.name
  }));
  customOptions = customOptions.filter(type =>
    isAdminCompany
      ? type
      : type.id === Enums.UserType.Operator ||
        type.id === Enums.UserType.Default
  );
  return [...options, ...customOptions];
};
