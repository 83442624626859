import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import 'assets/app-styles/scss/change-password.scss';
import { errors } from 'services/utils/variables';
import { setErrorClass } from 'services/utils/generic.methods';
import UILEyeShow from '@iconscout/react-unicons/icons/uil-eye-slash';
import UILEye from '@iconscout/react-unicons/icons/uil-eye';
import { useEffect } from 'react';

const ChangePasswordForm = ({
  requirePasswordReset,
  changePasswordFormIk,
  oldPasswordError
}) => {
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmNewPasswordShow, setConfirmNewPasswordShow] = useState(false);
  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] =
    useState(oldPasswordError);

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    changePasswordFormIk.handleChange(e);
    setFieldErrorOnSubmit(false);
  };

  useEffect(() => {
    setFieldErrorOnSubmit(oldPasswordError);
  }, [oldPasswordError]);

  /**
   * hide and show password
   */
  const toggleOldPassword = () => {
    setOldPasswordShow(oldPasswordShow ? false : true);
  };
  const toggleNewPassword = () => {
    setNewPasswordShow(newPasswordShow ? false : true);
  };
  const toggleConfirmNewPassword = () => {
    setConfirmNewPasswordShow(confirmNewPasswordShow ? false : true);
  };

  return (
    <>
      <Form
        className="form form-class white-background pb-0"
        onSubmit={changePasswordFormIk.handleSubmit}
        autoComplete="off"
      >
        {requirePasswordReset ? (
          <div className="warning-msg">
            As you previously forgot the password, The change password is
            mandatory, closing this window will redirect you to the login page
          </div>
        ) : null}
        <Row>
          <Col md={12}>
            <Form.Group
              controlId="oldPassword"
              className={setErrorClass(
                [changePasswordFormIk, fieldErrorOnSubmit],
                'oldPassword'
              )}
            >
              <Form.Control
                className="lined with-label"
                type={oldPasswordShow ? 'text' : 'password'}
                label="oldPassword"
                name="oldPassword"
                onBlur={changePasswordFormIk.handleBlur}
                onChange={e => handleOnChange(e)}
                value={changePasswordFormIk.values.oldPassword}
              />
              <Form.Label className="label-movable">
                {requirePasswordReset ? 'Temporary' : 'Old'} password
              </Form.Label>
              <i className="passEye" onClick={toggleOldPassword}>
                {oldPasswordShow ? (
                  <UILEye size="12" />
                ) : (
                  <UILEyeShow size="12" />
                )}
              </i>
              <p className="text-red-1 field-error">
                {changePasswordFormIk.errors.oldPassword &&
                  changePasswordFormIk.touched.oldPassword &&
                  errors.fieldError('password')}
                {fieldErrorOnSubmit && errors.fieldCorrection('password')}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group
              controlId="newPassword"
              className={`${setErrorClass(
                [changePasswordFormIk, fieldErrorOnSubmit],
                'newPassword'
              )} ${
                changePasswordFormIk.errors.newPassword &&
                changePasswordFormIk.touched.newPassword &&
                'is-invalid'
              }`}
            >
              <Form.Control
                className="lined with-label"
                type={newPasswordShow ? 'text' : 'password'}
                label="newPassword"
                name="newPassword"
                onBlur={changePasswordFormIk.handleBlur}
                onChange={e => handleOnChange(e)}
                value={changePasswordFormIk.values.newPassword}
              />
              <Form.Label className="label-movable">New password</Form.Label>
              <i className="passEye" onClick={toggleNewPassword}>
                {newPasswordShow ? (
                  <UILEye size="12" />
                ) : (
                  <UILEyeShow size="12" />
                )}
              </i>
              <p
                className="text-red-1 field-error"
                style={{
                  whiteSpace: 'unset'
                }}
              >
                {changePasswordFormIk.errors.newPassword &&
                  changePasswordFormIk.touched.newPassword &&
                  changePasswordFormIk.errors.newPassword}
                {fieldErrorOnSubmit && changePasswordFormIk.errors.newPassword}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group
              controlId="confirmNewPassword"
              className={`${setErrorClass(
                [changePasswordFormIk, fieldErrorOnSubmit],
                'confirmNewPassword'
              )} ${
                changePasswordFormIk.errors.confirmNewPassword &&
                changePasswordFormIk.touched.confirmNewPassword &&
                'is-invalid'
              }`}
            >
              <Form.Control
                className="lined with-label"
                type={confirmNewPasswordShow ? 'text' : 'password'}
                label="confirmNewPassword"
                name="confirmNewPassword"
                onBlur={changePasswordFormIk.handleBlur}
                onChange={e => handleOnChange(e)}
                value={changePasswordFormIk.values.confirmNewPassword}
              />
              <Form.Label className="label-movable">
                Confirm new password
              </Form.Label>
              <i className="passEye" onClick={toggleConfirmNewPassword}>
                {confirmNewPasswordShow ? (
                  <UILEye size="12" />
                ) : (
                  <UILEyeShow size="12" />
                )}
              </i>
              <p className="text-red-1 field-error">
                {changePasswordFormIk.errors.confirmNewPassword &&
                  changePasswordFormIk.touched.confirmNewPassword &&
                  changePasswordFormIk.errors.confirmNewPassword}
                {fieldErrorOnSubmit &&
                  changePasswordFormIk.errors.confirmNewPassword}
              </p>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ChangePasswordForm;
