import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { CompanyAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: []
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setCompaniesToState: (state, action) => {
      state.companies = [action.payload.data];
    },

    removeCompaniesFromState: (state, action) => {
      let companies = [...state.companies];
      let index = companies.findIndex(com => com.id === action.payload.id);
      companies.splice(index, 1);
      state.companies = companies;
    },

    updateCompanyToState: (state, action) => {
      if (action.payload.data.id) {
        let companies = [...state.companies];
        const index = companies.findIndex(
          company => company.id === action.payload.id
        );
        state.companies[index] = action.payload.data;
      }
    },

    removeAllCompaniesFromState: (state, action) => {
      state.companies = [];
    }
  }
});

export const {
  setCompaniesToState,
  removeCompaniesFromState,
  removeAllCompaniesFromState,
  updateCompanyToState
} = companiesSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getCompanyAsync = payload => (dispatch, getState) => {
  let companyAPI = CompanyAPI.get + '/' + payload.params.id;
  return ApiServices.getWithParams(companyAPI, payload)
    .then(async res => {
      let { data } = res;
      if (data?.success && data?.data) {
        await dispatch(setCompaniesToState(data));
        return Promise.resolve(data);
      }
      return Promise.reject(res);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  // }
};
/* called a selector and allows to select values from the state */
export const selectCompanies = state => {
  return state.companies.companies;
};

export const selectCompany = (state, id) => {
  let companies = state.companies.companies;
  if (id && companies.length) {
    let temp = companies.filter(c => c.id === id);
    if (temp.length) {
      return { company: temp[0] };
    } else return { company: {} };
  } else return { company: {} };
};

export const updateCompany = payload => {
  let companyAPI = CompanyAPI.put;
  return ApiServices.put(companyAPI, payload);
};

export const getAllCompanies = payload => {
  let companyAPI = CompanyAPI.getAll;
  return ApiServices.getWithParams(companyAPI, { params: payload });
};
export const exportAllCompanies = payload => {
  let companyAPI = CompanyAPI.export;
  return ApiServices.WCF_getWithParams(companyAPI, { params: payload });
};

export default companiesSlice.reducer;
