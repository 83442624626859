import React, { useState } from 'react';
import DateRangeDropdown from 'pub-components/DateRangeDropdown';
import { useEffect } from 'react';

const CustomDateFilter = React.forwardRef((props, ref) => {
  const handleChange = value => {
    let filterObj = props?.filterValue;
    filterObj.value = value;
    props.onChange(filterObj);
  };
  let initialDate =
    props?.filterValue?.value?.startDate && props.filterValue.value.endDate
      ? {
          startDate: new Date(props.filterValue.value.startDate),
          endDate: new Date(props.filterValue.value.endDate),
          key: 'selection'
        }
      : null;
  let id = props?.filterValue?.name;
  return (
    <div ref={ref}>
      <DateRangeDropdown
        onDateChange={handleChange}
        id={id}
        initialRange={initialDate}
        showValue={initialDate ? true : false}
      />
    </div>
  );
});

export default CustomDateFilter;
