import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ChatAPI, ChatTranferAPI } from 'api/endpoints';
import { Enums } from 'services/dropdown-enums';
import { getPlainStringFromHTMLElementAsString } from 'services/utils/validate';
import Chatroom from 'app/communication/services/chatroom-service';
import {
  camelCaseStringToNormalText,
  getCookie,
  setCookie,
  updateViewedUser
} from 'services/utils/generic.methods';
import { upsertChatRoom } from 'services/utils/messaging/message.methods';
import { logIt } from 'services/utils/logger';
import { db } from 'app/communication/services/indexedDbService';
import {
  SystemMsgTypes,
  showLastMessageFromMessagesForChatTypes
} from 'services/utils/specific-conditions';

const indexedDb = db();
const initialState = {
  status: 0,
  disconnectionTime: 0,
  incomingChats: [],
  allChats: [],
  activeChats: [],
  shelvedChats: [],
  unsentLeadChats: [],
  selectedChat: {},
  chats: {},
  splashScreen: { text: 'Loading components...', count: 0 },
  chatEnded: [],
  enableNotificationSound: true,
  enableAutoPickup: false,
  fetchingUnsentLeads: false,
  messageRecived: false,
  rejectedChatIds: [],
  optInLeads: [],
  totalUnreadMessagesCount: 0,
  unreadMessages: [],
  chatStack: [],
  accessibleChat: 0,
  activeOperators: []
};
/**
 * @name @createSlice
 * @description create redux store slice for Messaging Chats
 **/

export const MessagingChatSlice = createSlice({
  name: 'MessagingChats',
  initialState: { ...initialState },

  reducers: {
    /**
     * @description set Messaging Chats instance into store
     **/

    setDisconnectionTime: (state, action) => {
      state.disconnectionTime = action.payload;
    },
    setIncomingChatList: (state, action) => {
      //  add chats in incomingChats State
      state.incomingChats = action.payload;
    },

    setActiveChatList: (state, action) => {
      //  add chats in activeChats State
      state.activeChats = action.payload;
    },

    insertActiveChatsToList: (state, action) => {
      var activeChats = [...state.activeChats];
      if (action.payload.length) {
        action.payload.forEach(t => {
          if (!activeChats.includes(t)) {
            activeChats.push(t);
          }
        });
      }
      state.activeChats = activeChats;
    },

    setAllChatList: (state, action) => {
      //  add chats in allChats State
      state.allChats = action.payload;
    },

    insertAllChatsToList: (state, action) => {
      var allChats = [...state.allChats];
      if (action.payload.length) {
        action.payload.forEach(t => {
          allChats.push(t);
        });
      }
      state.allChats = allChats;
    },

    setShelvedChatList: (state, action) => {
      //  add chats in shelvedChat State
      state.shelvedChats = action.payload;
    },

    setUnsentLeadChatList: (state, action) => {
      //  add chats in allChats State
      state.unsentLeadChats = action.payload;
    },

    setChatStack: (state, action) => {
      //  add chat id in chatStack State
      let chatId = action.payload;
      let stack = state.chatStack.filter(id => id !== chatId);
      stack.push(chatId);
      state.chatStack = stack;
    },
    removeFromChatStack: (state, action) => {
      //  add chat id in chatStack State
      let chatId = action.payload;
      let stack = state.chatStack.filter(id => id !== chatId);
      state.chatStack = stack;
    },

    setSelectedChat: (state, action) => {
      //  add chat in selectedChat State
      let chatInfo = action.payload;
      let isFromIncomingSection = chatInfo.isFromIncomingSection;
      let selectedId = chatInfo.getId();
      let chatState = JSON.parse(JSON.stringify(state, undefined, 2));
      state.selectedChat = action.payload;
      if (!(selectedId in chatState.chats)) {
        let _incomingChats = state.incomingChats;
        let chat = _incomingChats.filter(
          (ch, id, obj) => ch.data.chatId === selectedId
        );
        let chatIndex = _incomingChats.findIndex(
          ch => ch.data.chatId === selectedId
        );
        if (chat.length) {
          chat[0]['hasSubscribed'] = true;
          state.incomingChats[chatIndex] = chat[0];
        }

        state.chats[`${selectedId}`] = {
          chatInfo: {
            ...chatInfo
          },
          updatedInfo: {
            callConnectStatus: Enums.CallConnectStatus.Idle
          },
          messages: [],
          inputMessage: '',
          isTyping: false,
          unreadMsgsCount: 0,
          status: true
        };
      } else if (
        selectedId in chatState.chats &&
        !chatState.chats[selectedId]['chatInfo']
      ) {
        state.chats[`${selectedId}`]['chatInfo'] = {
          ...chatInfo
        };
        state.chats[`${selectedId}`]['unreadMsgsCount'] = 0;
        state.chats[`${selectedId}`]['status'] = true;
        state.chats[`${selectedId}`].scroll = true;
      } else {
        state.chats[`${selectedId}`]['unreadMsgsCount'] = 0;
        state.chats[`${selectedId}`]['status'] = true;
        state.chats[`${selectedId}`].scroll = true;
      }

      //  Remove unused references from chats object
      let activeIds = [];
      let allIds = [];
      let shelvedIds = [];
      let unsentLeadsIds = [];
      let optInLeadsIds = [];
      if (state.activeChats.length) {
        activeIds = state.activeChats.map(chat => chat.getId());
      }

      if (state.allChats.length) {
        allIds = state.allChats.map(chat => chat.getId());
      }

      if (state.shelvedChats.length) {
        shelvedIds = state.shelvedChats.map(chat => chat.getId());
      }

      if (state.unsentLeadChats.length) {
        unsentLeadsIds = state.unsentLeadChats.map(chat => chat.chatId);
      }

      if (state.optInLeads.length) {
        optInLeadsIds = state.optInLeads.map(chat => chat.chatId);
      }

      let mergedIds = new Set([
        ...activeIds,
        ...allIds,
        ...shelvedIds,
        ...unsentLeadsIds,
        ...optInLeadsIds,
        ...state.chatStack // not remove chat if it is in chat stack for layered functionality
      ]);
      for (let chatId in state.chats) {
        if (
          !mergedIds.has(Number(chatId)) &&
          state.selectedChat.getId() !== chatId &&
          !isFromIncomingSection
        ) {
          delete state.chats[chatId];
        }
      }

      //  Remove ended chat references from chats object
      if (state.chatEnded.length) {
        state.chatEnded.forEach(chatId => {
          if (
            chatId in state.chats &&
            !unsentLeadsIds.includes(chatId) &&
            !state.chatStack.includes(chatId) // not remove chat if it is in chat stack for layered functionality
          ) {
            delete state.chats[chatId];
          }
        });

        state.chatEnded = [];
      }
    },
    setUnreadMessage: (state, action) => {
      let chatId = action.payload;
      // let currentPath = window.location.pathname;
      // if (
      //   chatId in state.chats &&
      //   (!state.selectedChat.getId ||
      //     (state.selectedChat.getId &&
      //       (state.selectedChat.getId() !== chatId ||
      //         currentPath !== '/portal/messaging')))
      // ) {
      //   state.chats[chatId]['unreadMsgsCount'] += 1;
      // }
      if (chatId in state.chats) {
        state.chats[chatId]['unreadMsgsCount'] += 1;
      }
    },
    setUnreadMessagesOnChatTiles: (state, action) => {
      if (state.unreadMessages.length && Object.keys(state.chats).length) {
        let index = state.unreadMessages.findIndex(
          unm => unm.ChatId === action.payload
        );
        if (index >= 0) {
          let { ChatId, Count } = state.unreadMessages[index];

          if (ChatId in state.chats) {
            state.chats[ChatId]['unreadMsgsCount'] = Count;
            let remain = [...state.unreadMessages].filter(
              unm => unm.ChatId !== action.payload
            );
            state.unreadMessages = remain;
          }
        }
      }
    },
    setTotalUnreadMessagesCount: (state, action) => {
      state.unreadMessages = action.payload;
    },

    decrementTotalUnreadMessagesCount: (state, chatId) => {
      if (state.chats[chatId.payload] != null) {
        if (state.chats[chatId.payload]['unreadMsgsCount']) {
          state.totalUnreadMessagesCount = checkDecrementValue(
            state.totalUnreadMessagesCount,
            state.chats[chatId.payload]['unreadMsgsCount']
          );

          state.chats[chatId.payload]['unreadMsgsCount'] = 0;
          window.document.title = window.originalTitle;
          clearInterval(window.tabTitleTimer);
        }
      }
    },
    clearSelectedChatUnreadMessageCount: (state, action) => {
      let chatId = action.payload;
      if (chatId in state.chats) {
        state.chats[`${chatId}`]['unreadMsgsCount'] = 0;
      }
    },
    setMessagesAsRead: (state, action) => {
      let chatId = action.payload;
      ApiServices.WCF_post(ChatAPI.setMessagesAsRead(chatId))
        .then(async response => {})
        .catch(async err => {});
    },
    setMessageLeadAsRead: (state, action) => {
      let leadId = action.payload;
      let markAsRead = false;
      state.optInLeads.map(t => {
        if (t.leadId === Number(leadId) && !t.isRead) {
          t.isRead = true;
          markAsRead = true;
        }
      });
      //mark as read in indexedDb
      // indexedDb.optInLeadsDbService.updateColumnByKey('leadId', leadId, {
      //   'jsonData.isRead': true
      // });
      if (markAsRead) {
        state.totalUnreadMessagesCount = checkDecrementValue(
          state.totalUnreadMessagesCount,
          1
        );
        ApiServices.WCF_post(ChatAPI.setMessageLeadAsRead(leadId)).catch(
          async err => {
            console.log(err);
          }
        );
      }
    },
    sortChatsByActivity: (state, action) => {
      let chatId = action.payload;
      let chat = state.activeChats.filter(item => item.getId() == chatId);
      if (chat && chat.length) {
        let stack = state.activeChats.filter(item => item.getId() !== chatId);
        stack.unshift(chat[0]);
        state.activeChats = stack;
      }
    },
    saveChatHistory: (state, action) => {
      let { data, chatId } = action.payload;
      if (chatId) {
        if (chatId in state.chats) {
          state.chats[`${chatId}`].scroll = true;
          let messages = state.chats[`${chatId}`].messages;
          if (messages && messages.length > 0) {
            // If there's messages with different chatIds then its visitor history
            let prevMsgs = messages.filter(msg => msg.chatId !== chatId);
            if (prevMsgs && prevMsgs.length > 0) {
              // prepend history msgs
              state.chats[`${chatId}`].messages = [...prevMsgs, ...data];
            } else {
              state.chats[`${chatId}`].messages = data;
            }
          } else {
            state.chats[`${chatId}`].messages = data;
          }
        }
      }
    },
    setShowPrevBtn: (state, action) => {
      let { showPrevBtn, chatId } = action.payload;
      if (chatId) {
        if (chatId in state.chats) {
          state.chats[`${chatId}`].showPrevBtn = showPrevBtn;
        }
      }
    },
    prepandChatHistory: (state, action) => {
      let { data, chatId } = action.payload;
      if (chatId) {
        if (chatId in state.chats) {
          state.chats[`${chatId}`].scroll = false;
          state.chats[`${chatId}`].messages = [
            ...data,
            ...state.chats[`${chatId}`].messages
          ];
        }
      }
    },
    saveChatMessage: (state, action) => {
      //  add chat in selectedChat State
      let data = action.payload;
      let selectedId = data.chatId;
      if (selectedId) {
        if (!(selectedId in state.chats)) {
          state.chats[`${selectedId}`] = {
            messages: [data],
            //  TODO:unreadMsgsCount:1 was added for MD-27, but now set to zero '0' for MD-169-subscribed-on-refresh scenario
            unreadMsgsCount: 0
          };
        } else {
          let chatState = JSON.parse(JSON.stringify(state, undefined, 2));
          let msgs = chatState.chats[selectedId].messages;
          if (msgs.length > 0) {
            // let lastIndex = msgs.length - 1;
            // let lastMsg = msgs[lastIndex];
            // if (lastMsg.id !== data.id) {
            if (!msgs.some(msg => msg.id === data.id)) {
              msgs.push(data);
              state.chats[selectedId].messages = msgs;
            }
          } else if (msgs.length === 0) {
            msgs.push(data);
            state.chats[selectedId].messages = msgs;
          }
          state.chats[`${selectedId}`].scroll = true;
        }
      }
    },
    setOptInLeads: (state, action) => {
      state.optInLeads = action.payload;
    },
    pushSplashScreenMeta: (state, action) => {
      state.splashScreen.text = action.payload.text;
      state.splashScreen.count += action.payload.count;
    },
    saveInputMessage: (state, action) => {
      //  add input text in current selected chat
      let data = action.payload;
      let selectedId = data.id;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`].inputMessage = data.text;
        if (data?.QTDetails) {
          state.chats[`${selectedId}`].QTDetails = data?.QTDetails;
        }
        if (!data.text) {
          state.chats[`${selectedId}`].QTDetails = null;
        }
      }
    },
    saveChatTypingIndicator: (state, action) => {
      //  add input text in current selected chat
      let data = action.payload;
      let selectedId = data.id;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`].isTyping = data.typing;
      }
    },

    setVisitorStatus: (state, action) => {
      // update visitor status on chats
      let data = action.payload;
      let selectedId = data.chatId;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`].status = data.status;
      }
    },

    pushChatRoom: (state, action) => {
      // TODO: remove duplication of chat and push to respective chat array by its type
      let { room, includeInSections, excludeInSections } = action.payload;
      logIt('log', 'pushChatRoom::', includeInSections);

      // update chatInfo with latest data if chat id exist in chats object
      if (room.getId() in state.chats && state.chats[room.getId()]?.chatInfo) {
        state.chats[room.getId()].chatInfo = room;
      }

      // add chat to active chat list
      if (includeInSections.includes(Enums.RoomType.ActiveChat)) {
        let activeList = [...state.activeChats];
        activeList = upsertChatRoom(activeList, room);
        state.activeChats = activeList;
      }

      // add chat to all chat list
      if (includeInSections.includes(Enums.RoomType.AllChat)) {
        let all = [...state.allChats];
        all = upsertChatRoom(all, room);
        state.allChats = all;
      }

      // add chat to incoming chat list
      if (
        includeInSections.includes(Enums.RoomType.IncomingChat) &&
        !state.rejectedChatIds.includes(room.getId()) // if chat is already rejected by own user then will not push in incomingChats array again
      ) {
        let incomingList = [...state.incomingChats];
        incomingList = upsertChatRoom(incomingList, room);
        state.incomingChats = incomingList;
      }

      // add chat to shelved chat list
      if (includeInSections.includes(Enums.RoomType.ShelvedChat)) {
        let shelvedList = [...state.shelvedChats];
        shelvedList = upsertChatRoom(shelvedList, room);
        state.shelvedChats = shelvedList;
      }

      // remove chat from incoming chat list
      if (excludeInSections.includes(Enums.RoomType.IncomingChat)) {
        state.incomingChats = state.incomingChats.filter(
          chat => room.getId() !== chat.getId()
        );
      }

      // remove chat from active chat list
      if (excludeInSections.includes(Enums.RoomType.ActiveChat)) {
        state.activeChats = state.activeChats.filter(
          chat => room.getId() !== chat.getId()
        );
        // remove chat's unread message count from chats state
        if (room.getId() in state.chats) {
          //remove from the total unread messages
          state.totalUnreadMessagesCount = checkDecrementValue(
            state.totalUnreadMessagesCount,
            state.chats[room.getId()].unreadMsgsCount
          );
          state.chats[room.getId()].unreadMsgsCount = 0;
        }
      }

      // remove chat from all chat list
      if (excludeInSections.includes(Enums.RoomType.AllChat)) {
        state.allChats = state.allChats.filter(
          chat => room.getId() !== chat.getId()
        );
      }

      // remove chat from shelved chat list
      if (excludeInSections.includes(Enums.RoomType.ShelvedChat)) {
        state.shelvedChats = state.shelvedChats.filter(
          chat => room.getId() !== chat.getId()
        );
      }
    },
    chatEnded: (state, action) => {
      if (!state.chatEnded.includes(action.payload)) {
        state.chatEnded.push(action.payload);
        //remove from indexedDb
        // indexedDb.deleteChatOnEnd(action.payload);
      }
    },
    setEnableNotificationSound: (state, action) => {
      state.enableNotificationSound = !!action.payload;
      updateViewedUser({ enableSound: !!action.payload });
    },
    setEnableAutoPickup: (state, action) => {
      state.enableAutoPickup = !!action.payload;
      updateViewedUser({ ap: !!action.payload });
    },
    setMessagesRecived: (state, action) => {
      state.messageRecived = !!action.payload;
    },
    endChatReference: (state, action) => {
      let id = action.payload;
      let incoming = state.incomingChats;
      let active = state.activeChats;
      let all = state.allChats;
      let shelved = state.shelvedChats;
      // let unsentLead = state.unsentLeadChats;

      incoming = incoming.filter(chat => chat.getId() !== id);
      active = active.filter(chat => chat.getId() !== id);
      all = all.filter(chat => chat.getId() !== id);
      shelved = shelved.filter(chat => chat.getId() !== id);
      // unsentLead = unsentLead.filter(chat => chat.chatId !== id);
      state.incomingChats = incoming;
      state.activeChats = active;
      state.allChats = all;
      state.shelvedChats = shelved;
      // state.unsentLeadChats = unsentLead;

      let endedIdIndex = state.chatEnded.indexOf(id);
      if (endedIdIndex) {
        state.chatEnded.splice(endedIdIndex, 1);
      }

      state.selectedChat = {};

      if (id in state.chats) {
        delete state.chats[id];
      }
    },

    removeFromIncomingSection: (state, action) => {
      let id = action.payload;
      let incoming = state.incomingChats.filter(chat => chat.getId() !== id);
      state.incomingChats = incoming;
    },

    removeFromOptInLeadsSection: (state, action) => {
      let id = action.payload;
      let excludedList = state.optInLeads.filter(x => x.chatId !== id);
      let excludedEntry = state.optInLeads.filter(x => x.chatId == id);
      state.optInLeads = excludedList;
      //push it in the active chat's list
      var activeChatsList = [...state.activeChats];

      // activeChatsList.unshift(excludedEntry[0]);
      // state.activeChats = activeChatsList;
    },

    //  add loading when rejecting chat
    addRejectLoadingInIncomingChat: (state, action) => {
      let { id, loading } = action.payload;
      let chatIndex = state.incomingChats.findIndex(
        chat => chat.getId() === id
      );
      let chat = state.incomingChats[chatIndex];
      state.incomingChats[chatIndex] = {
        ...chat,
        data: { ...chat.data, rejectLoading: loading }
      };
    },

    //  add rejected chat id in rejectedChatIds array,when successfully rejecting chat
    addRejectedChatId: (state, action) => {
      let ids = state.rejectedChatIds;
      ids.push(action.payload);
      state.rejectedChatIds = ids;
    },

    resetChatsOnLogout: (state, action) => {
      state.allChats = [];
      state.activeChats = [];
      state.incomingChats = [];
      state.shelvedChats = [];
      state.unsentLeadChats = [];
      state.selectedChat = {};
      state.chats = {};
      state.splashScreen = { text: 'Loading components...', count: 0 };
      state.chatEnded = [];
      state.optInLeads = [];
    },

    setUnsentLeadFetching: (state, action) => {
      state.fetchingUnsentLeads = action.payload;
    },
    saveChatToShelved: (state, action) => {
      let id = action.payload;
      let data = state.chats[id]?.chatInfo;
      state.activeChats = state.activeChats.filter(chat => chat.getId() !== id);
      if (!state.shelvedChats.some(item => item.getId() === id) && data) {
        state.shelvedChats.push(data);
      }
    },

    removeChatFromShelved: (state, action) => {
      let data = action.payload;
      state.shelvedChats = state.shelvedChats.filter(
        chat => chat.getId() !== data.id
      );
    },

    setCallConnectStatus: (state, action) => {
      let data = action.payload;
      let selectedId = data.chatId;
      if (selectedId in state.chats) {
        state.chats[`${selectedId}`]['updatedInfo']['callConnectStatus'] =
          data.status;
      }
    },
    setChatStatusToClientHandlingForOptInLeads: (state, action) => {
      let chatId = action.payload;
      if (chatId in state.chats) {
        state.chats[`${chatId}`]['updatedInfo']['status'] =
          Enums.ChatStatus.ClientHandling;
      }
      // indexedDb.optInLeadsDbService.removeRow(chatId);
    },
    setChatUpdatedInfoDataInChats: (state, action) => {
      // let data = action.payload;
      let allChats = state.chats;
      let { chatId, updatedInfoData } = action.payload;
      if (chatId in allChats && allChats[chatId]?.updatedInfo) {
        state.chats[`${chatId}`]['updatedInfo'] = {
          ...allChats[chatId]?.updatedInfo,
          ...updatedInfoData
        };
      } else if (chatId in allChats) {
        state.chats[`${chatId}`]['updatedInfo'] = {
          ...updatedInfoData
        };
      }
    },

    updateSelectedChat: (state, action) => {
      if ('data' in state.selectedChat) {
        state.selectedChat['data'] = {
          ...state.selectedChat['data'],
          ...action.payload
        };
      }
    },

    //  reset SelectedChat state to empty object {}
    removeSelectedChat: (state, action) => {
      state.selectedChat = {};
    },

    setAccessibleChat: (state, action) => {
      state.accessibleChat = action.payload;
      setCookie('X-Auth-Accessor-Id', state.accessibleChat, 3);
    },
    setUserStatus: (state, action) => {
      state.status = action.payload;
    },
    setActiveOperators: (state, action) => {
      state.activeOperators = action.payload;
    },
    removeChatIdFromChats: (state, action) => {
      let id = action.payload;
      let allChats = state.chats;
      if (id in allChats) {
        delete allChats[id];
        state.chats = allChats;
      }
    }
  }
});

export const {
  setShowPrevBtn,
  setDisconnectionTime,
  setAllChatList,
  insertAllChatsToList,
  setActiveChatList,
  insertActiveChatsToList,
  setIncomingChatList,
  setShelvedChatList,
  setSelectedChat,
  saveChatHistory,
  prepandChatHistory,
  saveChatMessage,
  pushChatRoom,
  pushSplashScreenMeta,
  saveInputMessage,
  chatEnded,
  saveChatTypingIndicator,
  setEnableNotificationSound,
  endChatReference,
  removeFromIncomingSection,
  setUnreadMessage,
  clearSelectedChatUnreadMessageCount,
  setVisitorStatus,
  setUnsentLeadChatList,
  setUnsentLeadFetching,
  resetChatsOnLogout,
  updateSelectedChat,
  removeSelectedChat,
  saveChatToShelved,
  removeChatFromShelved,
  setMessagesRecived,
  setCallConnectStatus,
  setChatUpdatedInfoDataInChats,
  addRejectLoadingInIncomingChat,
  setEnableAutoPickup,
  addRejectedChatId,
  setOptInLeads,
  removeFromOptInLeadsSection,
  setTotalUnreadMessagesCount,
  decrementTotalUnreadMessagesCount,
  // decrementOneFromTotalUnreadMessagesCount,
  setChatStatusToClientHandlingForOptInLeads,
  setChatStack,
  removeFromChatStack,
  setMessagesAsRead,
  setMessageLeadAsRead,
  sortChatsByActivity,
  setAccessibleChat,
  setUnreadMessagesOnChatTiles,
  setActiveOperators,
  removeChatIdFromChats
} = MessagingChatSlice.actions;

// check Decrement in total Unread message Count

const checkDecrementValue = (prevCount, newCount) => {
  let value = prevCount - newCount;
  return value > 0 ? value : 0;
};

/* called a selector and allows to select values from the state */
export const getAssignedGroups = () => {
  return ApiServices.WCF_get(ChatAPI.getGroups);
};

export const getIncomingChatList = state => {
  return state.MessagingChats.incomingChats;
};
export const getActiveChatList = state => {
  return state.MessagingChats.activeChats;
};

export const getShelvedChatList = state => {
  return state.MessagingChats.shelvedChats;
};

export const getUnsentLeads = state => {
  return state.MessagingChats.unsentLeadChats;
};
export const getUserStatus = state => {
  return state.MessagingChats.status;
};

export const getAutoPickupEnable = state => {
  return state.MessagingChats.enableAutoPickup;
};

export const getUnsentLeadsLoading = state => {
  return state.MessagingChats.fetchingUnsentLeads;
};

export const getUnSubscribedIncomingChatList = state => {
  return state.MessagingChats.incomingChats.filter(chat => !chat.hasSubscribed);
};

export const getLastClosedChatFromStack = state => {
  let length = state.MessagingChats.chatStack?.length;
  if (length > 1) {
    let chatId = state.MessagingChats.chatStack[length - 2];
    let chat = state.MessagingChats.chats[chatId]?.chatInfo;
    return chat || null;
  }
  return null;
};

export const isActiveChatUnsubscribed = state => chatId => {
  let allChats = state.MessagingChats.chats;
  let isSubscribed = false;
  if (chatId in allChats && allChats[chatId].chatInfo) {
    isSubscribed = true;
  }

  return isSubscribed;
};
export const getOptInLeads = state => {
  return state.MessagingChats.optInLeads;
};

export const getTotalOptInLeadsCount = state => {
  var count = 0;
  if (state.MessagingChats.optInLeads.length) {
    state.MessagingChats.optInLeads.map(t => {
      if (!t.isRead) {
        count++;
      }
    });
  }
  return count;
};

export const getAllChatList = state => {
  return state.MessagingChats.allChats;
};
export const getSplashScreenMeta = state => {
  return state.MessagingChats.splashScreen;
};
export const getSelectedChat = state => {
  return state.MessagingChats.selectedChat;
};
export const getAllChats = state => {
  return state.MessagingChats.chats;
};
export const getDisconnectionTime = state => {
  return state.MessagingChats.disconnectionTime;
};
export const getSelectedChatId = state => {
  let selectedChat = state.MessagingChats.selectedChat;
  if (Object.keys(selectedChat).length) {
    return selectedChat.getId();
  } else {
    return null;
  }
};
export const getSelectedChatById = chatId => state => {
  return state.MessagingChats.chats[chatId];
};
export const getChatInfoById = chatId => state => {
  return state.MessagingChats.chats[chatId]?.chatInfo || {};
};

export const getChatUpdatedInfoById = chatId => state => {
  return state.MessagingChats.chats[chatId]?.updatedInfo || {};
};

export const getSelectedChatCallStatus = chatId => state => {
  let status = Enums.CallConnectStatus.Idle;
  if (state.MessagingChats.chats[chatId]) {
    let info = state.MessagingChats.chats[chatId]?.updatedInfo;
    if (info) {
      status = info?.callConnectStatus;
    }
  }

  return status;
};

export const getSelectedChatLastMessage = state => chatId => {
  let lastMessage = { text: '' };
  let chatData = state.MessagingChats.chats[chatId];
  let selectedChat = state.MessagingChats.selectedChat;
  if (chatData && chatData?.chatInfo) {
    let initiatedBy = chatData?.chatInfo?.data?.initiatedBy;
    let messages = chatData?.messages;
    let isChatEnded = messages[messages.length - 1]?.isChatEnded;
    if (
      (selectedChat &&
        Object.keys(selectedChat).length &&
        (chatData?.chatInfo?.data?.isOptInConsentGiven ||
          showLastMessageFromMessagesForChatTypes.includes(initiatedBy))) ||
      isChatEnded
    ) {
      if (messages.length) {
        lastMessage = messages[messages.length - 1];
      }
    } else {
      let msg = chatData?.chatInfo?.data?.lastMessage;
      lastMessage.text = getPlainStringFromHTMLElementAsString(msg) || '';
      lastMessage.createdOn = chatData?.chatInfo?.data?.lastActivityDate;
      lastMessage.userId = chatData?.chatInfo?.data?.lastUserId;
      lastMessage.isChatEnded = isChatEnded;
    }
  } else if (chatData && chatData?.messages) {
    let messages = chatData?.messages;
    if (messages?.length) {
      lastMessage = messages[messages.length - 1];
    }
  }

  return lastMessage;
};

export const markChatMessagesAsRead = payload => async dispatch => {
  dispatch(decrementTotalUnreadMessagesCount(payload));
  dispatch(setMessagesAsRead(payload));
};

export const endChatAsync = payload => async dispatch => {
  return ApiServices.WCF_post(ChatAPI.endChat(payload))
    .then(async response => {
      await dispatch(chatEnded(payload));
      return Promise.resolve(response.data.success);
    })
    .catch(async err => {
      return Promise.resolve('Error:' + err);
    });
};

export const changeMessagingDashboardStatusAsync =
  payload => async dispatch => {
    return ApiServices.WCF_post(ChatAPI.messagingDashboardStatus(payload))
      .then(async response => {
        // await dispatch(setStatus(response.data.success));
        return Promise.resolve(response.data.success);
      })
      .catch(async err => {
        return Promise.resolve('Error:' + err);
      });
  };

export const updateUnsentLeadStatus = payload => async dispatch => {
  return ApiServices.WCF_post(ChatAPI.updateUnsentLead(payload))
    .then(async response => {
      if (response.data.success) {
        dispatch(updateSelectedChat({ isLead: payload.isLead }));
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      return Promise.resolve('Error:' + err);
    });
};

export const getUnsentLeadListAsync = () => async dispatch => {
  dispatch(setUnsentLeadFetching(true));
  return ApiServices.WCF_get(ChatAPI.getUnsentLeads)
    .then(async response => {
      if (response.data.success) {
        await dispatch(setUnsentLeadChatList(response.data.data));
        dispatch(setUnsentLeadFetching(false));
        dispatch(updateSelectedChat({ fetchLeads: false }));
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      dispatch(setUnsentLeadFetching(false));
      return Promise.resolve('Error:' + err);
    });
};

export const getChatRoomData = payload => async dispatch => {
  return ApiServices.WCF_get(ChatAPI.getChatRoomData(payload))
    .then(async response => {
      if (response.data.success) {
        let chat = new Chatroom(response.data.data);
        return Promise.resolve(chat);
      } else {
        return Promise.reject(response.data.error);
      }
    })
    .catch(async err => {
      return Promise.reject(err);
    });
};

export const getLastConvo =
  ({ lastChatId, chatId }) =>
  async dispatch => {
    return ApiServices.WCF_get(ChatAPI.getLastConvo(lastChatId))
      .then(async response => {
        if (response.data.success) {
          if (response.data.data.length > 0) {
            let messages = response.data.data.reverse();
            dispatch(prepandChatHistory({ data: messages, chatId: chatId }));
          }
          return Promise.resolve(response.data.data);
        } else {
          return Promise.reject(response.data.error);
        }
      })
      .catch(async err => {
        return Promise.reject(err);
      });
  };

export const getVisitorHistory = payload => {
  return ApiServices.WCF_getWithParams(ChatAPI.getVisitorHistory, {
    params: payload
  })
    .then(async response => {
      if (response.data.success) {
        return Promise.resolve(response.data.data);
      } else {
        return Promise.reject(response.data.error);
      }
    })
    .catch(async err => {
      return Promise.reject(err);
    });
};

export const getTranscript = payload => {
  return ApiServices.WCF_get(ChatAPI.getTranscript(payload))
    .then(async response => {
      if (response.data.success) {
        return Promise.resolve(response.data.data);
      } else {
        return Promise.reject(response.data.error);
      }
    })
    .catch(async err => {
      return Promise.reject(err);
    });
};

export const getSelectedChatFirstMessage = state => chatId => {
  let firstMessage = null;
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      firstMessage = messages[0];
    }
  }

  return firstMessage;
};

export const getSelectedChatLastVisitorMessage = state => chatId => {
  let visitorLastMessage = null;
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      visitorLastMessage = messages
        .slice()
        .reverse()
        .find(msg => msg.userId === 0);
    }
  }

  return visitorLastMessage;
};

export const getSelectedChatAllVisitorMessages = state => chatId => {
  let visitorMessages = [];
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      visitorMessages = messages.filter(
        msg => msg.userId === Enums.ChatMessageType.Visitor
      );
    }
  }

  return visitorMessages;
};

export const getSelectedChatLastVisitorOrUserMessage = state => chatId => {
  let visitorLastMessage = null;
  let chatData = state.MessagingChats.chats[chatId];
  if (chatData) {
    let messages = chatData.messages;
    if (messages && messages.length) {
      visitorLastMessage = messages.filter(
        msg => !SystemMsgTypes.includes(msg.userId)
      );
      visitorLastMessage = visitorLastMessage[visitorLastMessage.length - 1];
    }
  }

  return visitorLastMessage;
};

export const animateTabTitleOnMessageReceived = state => async dispatch => {
  clearInterval(window.tabTitleTimer);
  window.tabTitleTimer = setInterval(function () {
    window.document.title =
      window.document.title == window.originalTitle
        ? 'New message arrived'
        : window.originalTitle;
  }, 2000);
};

export const getSelectedChatStarted = state => chatId => {
  let isStarted = false;
  if (state.MessagingChats.chats[chatId]) {
    let messages = state.MessagingChats.chats[chatId].messages;
    if (messages && messages.length) {
      isStarted = true;
    }
  }

  return isStarted;
};

export const getSelectedChatTypingIndicator = state => chatId => {
  let typing = false;
  if (state.MessagingChats.chats[chatId]) {
    typing = state.MessagingChats.chats[chatId].isTyping;
  }

  return typing;
};

export const getSelectedVisitorStatus = state => chatId => {
  let status = false;
  if (state.MessagingChats.chats[chatId]) {
    status = state.MessagingChats.chats[chatId].status;
  }

  return status;
};

export const getEnableNotificationSound = state => {
  return state.MessagingChats.enableNotificationSound;
};
export const getUnreadMsgsCountByChatId = state => chatId => {
  let count = 0;
  if (state.MessagingChats.chats[chatId]) {
    count = state.MessagingChats.chats[chatId].unreadMsgsCount;
  }

  return count;
};

export const getTotalUnreadMessagesCount = state => {
  let count = 0;
  let chats = state.MessagingChats.chats || {};
  Object.keys(chats).forEach(key => {
    count += chats[key].unreadMsgsCount;
  });
  return count;
};

export const getAllUnreadMsgsCounts = state => {
  let count = 0;
  var qualifiedList = ['chats', 'activeChats'];
  for (let i = 0; i < qualifiedList.length; i++) {
    let chatsLength = Object.keys(
      state.MessagingChats[qualifiedList[i]]
    ).length;
    if (chatsLength) {
      for (let chat in state.MessagingChats[qualifiedList[i]]) {
        count += state.MessagingChats[qualifiedList[i]][chat].unreadMsgsCount;
      }
    }
  }

  return count;
};
export const getMessageRecived = state => {
  return state.MessagingChats.messageRecived;
};
export const getUnreadMessages = state => {
  return state.MessagingChats.unreadMessages;
};

export const getVisitorInfoName = state => chat => {
  let visitorName = '';
  let chatId = chat.chatId ? chat.chatId : chat.getId();
  let allChats = state.MessagingChats.chats;
  let roomData = allChats[chatId]?.chatInfo?.data;
  let savedData = allChats[chatId]?.updatedInfo;

  let removeWhiteSpace = true;
  let getTitleCase = name =>
    camelCaseStringToNormalText(name, 'heading', removeWhiteSpace).trim();
  if (chat.status == Enums.ChatStatus.ClientHandling) {
    visitorName = chat.firstName + ' ' + chat.LastName;
  } else if (
    (chatId in allChats && roomData?.visitorInfoName) ||
    savedData?.visitorInfoName
  ) {
    visitorName = savedData?.visitorInfoName || roomData?.visitorInfoName;
    return getTitleCase(visitorName);
  } else if (chat && chat?.data?.visitorInfoName) {
    visitorName = chat?.data?.visitorInfoName;
    return getTitleCase(visitorName);
  } else {
    return visitorName;
  }
};

//  update transfer chat response accept/reject
export const onTransferChatUpdate = payload => async dispatch => {
  return ApiServices.WCF_post(ChatTranferAPI.chatTransferUpdate(payload))
    .then(async response => {
      if (response.data.success) {
        // let chat = new Chatroom(response.data.data);
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      return Promise.reject('Error:' + err);
    });
};

//  reject chat api function
export const onChatReject = payload => async dispatch => {
  return ApiServices.WCF_post(ChatAPI.rejectChat(payload))
    .then(async response => {
      if (response.data.success) {
        // let chat = new Chatroom(response.data.data);
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      return Promise.reject('Error:' + err);
    });
};

// get rejected chat ids array
export const getRejectedChatIds = state => {
  return state.MessagingChats.rejectedChatIds;
};

// get all history chats
export const getAllChatsHistory = payload => {
  let chatsHistory = ChatAPI.getChatsHistory;
  return ApiServices.WCF_getWithParams(chatsHistory, { params: payload });
};

// get active operators list by chatId and currentUserId
export const getActiveOperatorsListAsync = payload => async dispatch => {
  let endpoint = ChatAPI.getOperators(payload);
  return ApiServices.WCF_get(endpoint)
    .then(async response => {
      if (response.data.success) {
        await dispatch(setActiveOperators(response.data.data || []));
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      return Promise.resolve('Error:' + err);
    });
};

export const getActiveOperators = state => {
  return state.MessagingChats.activeOperators || [];
};

// transfer chat to operator by chatId and userId of active operator
export const transferChatToOperator = payload => {
  let endpoint = ChatAPI.transferChatToOperator(payload);
  return ApiServices.WCF_get(endpoint)
    .then(async response => {
      if (response.data.success) {
        return Promise.resolve(response.data.data);
      }
    })
    .catch(async err => {
      return Promise.resolve('Error:' + err);
    });
};

export default MessagingChatSlice.reducer;
