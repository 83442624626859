import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { verifyJwt, login, logout } from 'store/slices/auth/authSlice';
import './styles.scss';
import storage from 'services/utils/storage';
import useQuery from 'routes/searchQueryHook';
import { errors, toastMsg } from 'services/utils/variables';
import {
  convertLegacyPortalLinkToCustomerPortal,
  createReturnUrl
} from 'services/utils/generic.methods';

const SSO = props => {
  const jwt = props.match.params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let query = useQuery();
  let redirectUrl = query.get('redirect');
  const profile = storage.getItem('Profile');
  //1. Verify jwt token with API
  const getJwtResponse = async () => {
    try {
      if (jwt === 'logout') {
        if (!location?.hash && !location?.search) {
          storage.deleteItem('SSO-return-location');
        }
        const _hostKey = query.get('hostKey');
        setTimeout(() => {
          history.push('/login' + (_hostKey ? '/' + _hostKey : ''));
        }, 1500);
        return;
      }
      const response = await verifyJwt(jwt);
      if (response?.data?.data?.IsAuthenticated) {
        const payload = {
          ...response.data.data,
          JWT: jwt,
          SSOVerification: true
        };
        dispatch(login(payload));
        if (payload.NewPortalEnabled) {
          const location = JSON.parse(storage.getItem('SSO-return-location'));
          let path = '';
          if (location && (location?.hash || location?.search)) {
            path = location.pathname + (location?.hash || location?.search);
          }
          if (!redirectUrl && !path) {
            history.push('/home');
          } else if (redirectUrl) {
            history.push(redirectUrl);
            // SSO-return-location is exist so we need to remove it from here too
            // remove return url from localstorage
            storage.deleteItem('SSO-return-location');
          } else if (path) {
            let url = location?.pathname.includes('sso/logout')
              ? convertLegacyPortalLinkToCustomerPortal(location)
              : path;
            // remove return url from localstorage
            storage.deleteItem('SSO-return-location');
            history.push(url);
          }
        }
      } else {
        history.push('/login');
        toast.error(toastMsg.errors.loginExpired);
      }
    } catch (error) {
      toast.error(toastMsg.errors.loginExpired);
      history.push('/login');
    }
  };

  useEffect(() => {
    if (location?.hash || location?.search) {
      var locationObj = location;
      if (locationObj.search.startsWith('?/')) {
        locationObj.search = locationObj.search.substring(
          1,
          locationObj.search.length
        );
      }
      // SSO-121 - need to logout for Old Portal from this trigger below
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.src =
        '//' + window.location.host.replace('my.', '') + '/Pages/Logout.aspx';
      head.appendChild(script);
      storage.setItem('SSO-return-location', locationObj);
    }
    const payload = {
      skipSSOlogout: true
    };
    dispatch(logout(payload));
    getJwtResponse();
  }, [props, profile]);
  //2. After verification get the api response i.e the user profile store the Auth and profile in locat storage.

  //3. redirect to dashboard route.
  return (
    <div className="d-flex justify-content-center align-items-center sso-no-data">
      <div className="verify-container">
        {/* <i className="uil uil-spinner spinner ml-2 spinner-size"></i> */}
        <br />
        Please wait. Verification is in process...
      </div>
    </div>
  );
};

export default SSO;
