import React from 'react';
import { Form } from 'react-bootstrap';
import { errors } from 'services/utils/variables';
import { setErrorClass } from 'services/utils/generic.methods';
import UILEyeShow from '@iconscout/react-unicons/icons/uil-eye-slash';
import UILEye from '@iconscout/react-unicons/icons/uil-eye';

export const FormGroupField = ({
  controlId = 'oldPassword',
  dataFormIk,
  fieldErrorOnSubmit,
  type = 'text',
  handleOnChange,
  label = 'Label',
  toggleButton,
  disabled = true,
  show
}) => (
  <Form.Group
    controlId={controlId}
    className={setErrorClass([dataFormIk, fieldErrorOnSubmit], controlId)}
  >
    <Form.Control
      className="lined with-label"
      type={show && type === 'password' ? 'text' : type}
      label={controlId}
      name={controlId}
      disabled={disabled}
      onBlur={dataFormIk.handleBlur}
      onChange={e => handleOnChange(e)}
      value={dataFormIk.values[controlId]}
    />
    <Form.Label className="label-movable">{label}</Form.Label>
    {type === 'password' ? (
      <i className="passEye" onClick={toggleButton}>
        {show ? <UILEye size="12" /> : <UILEyeShow size="12" />}
      </i>
    ) : null}
    <p className="text-red-1 field-error">{dataFormIk.errors[controlId]}</p>
  </Form.Group>
);

export const FormGroupToggleField = ({
  controlId = 'oldPassword',
  dataFormIk,
  fieldErrorOnSubmit,
  type = 'text',
  handleChecked,
  toggleButton,
  show,
  label = 'Label',
  //   checked = false,
  disabled = true
}) => (
  <Form.Group
    controlId={controlId}
    // className={setErrorClass([dataFormIk, fieldErrorOnSubmit], controlId)}
  >
    {/* <Form.Control
      className="lined with-label"
      type={type}
      label={controlId}
      name={controlId}
      disabled={true}
      value={dataFormIk.values[controlId]}
    /> */}
    <div className="lined with-label form-control">
      <Form.Label className="label-center">{label}</Form.Label>
    </div>
    {/* <i onClick={toggleButton}>
      {show ? <UILEye size="12" /> : <UILEyeShow size="12" />}
    </i> */}
    <div className="toggleBtn">
      <Form.Check
        type="switch"
        id={controlId}
        checked={dataFormIk.values[controlId]}
        onChange={e => handleChecked(e)}
        title={label}
        disabled={disabled}
      />
    </div>

    {/* <p className="text-red-1 field-error">
      {dataFormIk.errors[controlId] &&
        dataFormIk.touched[controlId] &&
        errors.fieldError(label)}
      {fieldErrorOnSubmit && errors.fieldCorrection(label)}
    </p> */}
  </Form.Group>
);
